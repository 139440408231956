import TargetContent from "../enum/TargetContent";
import { createContext, useEffect, useState } from "react";
import getSlugByTargetContent from "../utils/getSlugByTargetContent";
import history from "history/browser";

type MenuContextType = {
    currentActiveContent: TargetContent,
    resetContent: () => void,
    setActiveContent: (targetContent: TargetContent) => void
}

export const MenuContext = createContext<MenuContextType>({} as MenuContextType);

export const MenuProvider = ({ children }) => {

    const [currentActiveContent, setCurrentActiveContent] = useState<TargetContent>(TargetContent.DEFAULT);

    const resetContent = () => {
        setCurrentActiveContent(TargetContent.DEFAULT);
        toggleProjectViewListVisibility(TargetContent.DEFAULT);

    }

    const setActiveContent = (newItem: TargetContent) => {
        setCurrentActiveContent(newItem);
        toggleProjectViewListVisibility(newItem);
    }

    const toggleProjectViewListVisibility = (TargetContent) => {
        const singleProjectViewList = document.getElementById('single-project-view-list')
        if (!singleProjectViewList) return false

        const workPageID = 1
        const isWorkPage = TargetContent == workPageID
        console.log('t' + TargetContent);

        isWorkPage ? singleProjectViewList.classList.add('visible') : singleProjectViewList.classList.remove('visible')
    }

    useEffect(() => {
        if (currentActiveContent && currentActiveContent !== TargetContent.DEFAULT) {
            console.warn("SLUG CHANGE");
            const currentActivePage = document.getElementsByClassName("page active")[0];
            if (currentActivePage) {
                currentActivePage.classList.add("exit");
                setTimeout(() => {
                    currentActivePage.classList.remove("active");
                    currentActivePage.classList.remove("exit");
                    window.scrollTo(0, 0);
                }, 600);
            }

            const slug = getSlugByTargetContent(currentActiveContent);
            if (!document.getElementById(slug)) return

            history.replace(`/${slug}`);
            document.body.classList.add("page-open");
            document.getElementById(slug).classList.add("active");

        }
    }, [currentActiveContent]);

    return (
        <MenuContext.Provider value={{ currentActiveContent, resetContent, setActiveContent }}>
            {children}
        </MenuContext.Provider>
    )
}
