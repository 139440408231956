import { useEffect } from 'react';
import NProgress from 'nprogress';
import Container from 'react-bootstrap/Container';

function SuspenseLoader() {
    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return (
        <Container>
            Loading...
        </Container>
    );
}

export default SuspenseLoader;
