import React from 'react';
import './App.css';
import {useRoutes} from "react-router";
import routes from "./router/router";

function CMQ() {

  const content = useRoutes(routes);

  return (
      <div className={"App"}>
        {content}
      </div>
  );
}

export default CMQ;
