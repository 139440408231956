import TargetContent from "../enum/TargetContent";

const getSlugByTargetContent = (content:TargetContent) => {

    switch (content) {
        case TargetContent.WORK:
            return "work";
        case TargetContent.BENEFIT:
            return "benefit";
        case TargetContent.SALOTTO:
            return "salotto";
        case TargetContent.PEOPLE:
            return "people"; 
        default:
            return "";
    }
}

export default getSlugByTargetContent;
